@font-face {
  font-family: montserratHK;
  src: url(./assets/font/MStiffHei-HK-Medium.ttf);
}

* {
  font-family: montserratHK !important;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

.content-container {
  /* overflow-x: hidden; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 90vh;
}

.font_righteous {
  font-family: "Righteous", cursive;
}

.row {
  justify-content: center;
}
/*====================
    GENERAL CONSTANTS
======================*/

.bck_black {
  background: #3c3c3c;
}
.bck_white {
  background: #ffffff;
}
.bck_red {
  background: #ff4800;
}
.bck_yellow {
  background: #ffa800;
}

/*====================
        HEADER
======================*/

header {
  padding: 10px 0px;
  transition: all 300ms ease-in;
}

header .header_logo {
  flex-grow: 1;
}
header .header_logo_venue {
  font-size: 30px;
}

header .header_logo_title {
  text-transform: uppercase;
}

/*====================
        QUESTIONS
======================*/
#question {
  margin-top: 20px;
  /* background-color: #F57C00; */
  color: 000;
  padding: 10px;
  text-align: center;
}

#question h4 {
  background-color: #000;
  color: #0b0e21;
  display: inline-block;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 600;
}

#question p {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  padding-left: 50px;
  padding-right: 50px;
}

/*====================
        ANSWERS
======================*/

#answers ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15vh;
}

#answers li {
  /* background-color: #fff; */
  background-color: transparent;
  /* border: 2px solid #FF9800; */
  min-height: 70px;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  transition: color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#answers li span {
  background-color: #ff9800;
  color: #fff;
  font-size: 30px;
  flex: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

#answers p {
  color: white;
}

#answers li p {
  color: #0094da;
  font-size: 16px;
  font-weight: 600;
  flex: calc(100% - 75px);
  margin: auto 20px;
  transition: color 0.3s ease;
}

#answers li:after {
  display: block;
}

#answers li:hover {
  /* transform: scale(1.03); */
}

#answers li.right {
  border-color: #4caf50;
  color: #fff;
  background-color: #4caf50;
}

#answers li.right span {
  background-color: #4caf50;
}

#answers li.right p {
  color: #fff;
}

#answers li.wrong {
  border-color: #dc0a0a;
  color: #dc0a0a;
  background-color: #dc0a0a;
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

#answers li.wrong span {
  background-color: #dc0a0a;
}

#answers li.wrong p {
  color: #fff;
  background-color: #dc0a0a;
}

#submit {
  text-align: center;
  margin: 20px 0;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.fancy-btn {
  border: 2px solid #ff9800;
  border-radius: 0;
  background-color: #ff9800;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 75px;
  margin: 0 auto;
  text-transform: uppercase;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.fancy-btn:hover {
  background-color: #fff;
  color: #0b0e21;
}

/*====================
        POPUP CSS
======================*/

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  /* background-image: url(../assets/01_crystal_ball.png); */
  background-size: cover;
  background-position: center;
  height: 30vh;
  text-align: center;
  /* background-color: #fff; */
  border-radius: 10px;
  overflow: hidden;
}

.popup h1 {
  background-color: #ff9800;
  color: white;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  margin-top: 0;
}

.popup p {
  font-size: 18px;
  letter-spacing: 1px;
  margin: 20px 10% 0;
}

.popup .popupButton {
  text-align: center;
}

/*====================
        FOOTER
======================*/

footer {
  position: fixed;
  width: 100%;
  height: 10vh;
  bottom: 0;
  padding: 0px 0px;
  text-align: center;
  font-size: 60px;
  color: #ffffff;
  background: red;
}

footer .footer_copyright {
  font-size: 18px;
}

.footer_copyright a {
  text-decoration: none;
  color: #0094da;
}

.background-video,
.background-gif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-video video,
.background-gif img {
  object-fit: cover;
  object-position: center;
}

.video-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
