.container {
  margin-top: 26vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 80vh;
  padding-left: 15vw;
  padding-right: 15vw;
}

.descriptionContainer {
  color: white;
  font-size: 2vh;
  text-align: center;
}

.startButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #6b63b5;
  border-radius: 20px;
  margin-top: 7vh;
  width: 80%;
}

.linkButton {
  color: white;
  text-decoration: none;
  padding: 1vh;
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.descriptionParagraph {
  opacity: 0;
  animation: fadeInFromTop 3s ease-in-out forwards;
}

.descriptionParagraph:nth-child(1) {
  animation-delay: 0.5s;
}

.descriptionParagraph:nth-child(2) {
  animation-delay: 1.5s;
}

.descriptionParagraph:nth-child(3) {
  animation-delay: 2.5s;
}

.descriptionParagraph:nth-child(4) {
  animation-delay: 4s;
}
