.texim-ai {
  align-items: center;
  background-color: var(--brandpurple-6);
  display: flex;
  flex-direction: column;
  position: relative;
}

.texim-ai .navbar-logo-left {
  align-items: flex-start;
  align-self: stretch;
  background: linear-gradient(to bottom, rgb(25, 14, 70), transparent);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.texim-ai .navbar-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 16px 8px;
  position: relative;
  width: 1200px;
}

.texim-ai .navbar-content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.texim-ai .brand-logos {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 6px;
  position: relative;
}

.texim-ai .text-wrapper {
  color: var(--brandoff-white);
  font-family: "Almendra", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.texim-ai .navbar-menu {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.texim-ai .navbar-link {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 24px 12px;
  position: relative;
}

.navbar-link:hover {
  cursor: pointer;
}

.texim-ai .navbar-div {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.texim-ai .navbar-button {
  align-items: center;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(180.57, 42.79, 186.48) 4.67deg,
    rgb(161.31, 44.1, 188.03) 23.65deg,
    rgb(139.79, 45.57, 189.77) 44.86deg,
    rgb(120.6, 46.88, 191.32) 72.46deg,
    rgb(107.99, 47.75, 192.34) 82.5deg,
    rgb(75, 50, 195) 127.99deg,
    rgb(87.68, 49.13, 193.98) 160.97deg,
    rgb(99.31, 48.34, 193.04) 178.46deg,
    rgb(115.81, 47.21, 191.7) 189.48deg,
    rgb(141.11, 45.48, 189.66) 202.95deg,
    rgb(166.38, 43.76, 187.62) 230.66deg,
    rgb(185.39, 42.46, 186.09) 251.35deg,
    rgb(208.43, 40.88, 184.23) 276.44deg,
    rgb(236, 39, 182) 306.45deg,
    rgb(199.41, 41.5, 184.95) 331.68deg
  );
  border-radius: 99px;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 32px;
  position: relative;
}

.texim-ai .navbar-button:hover {
  box-shadow: 0px 0px 60px #ec27b699;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.texim-ai .text-wrapper-2 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.texim-ai .card-section {
  /* background-color: #0d0420; */
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 80px 120px 64px;
  position: relative;
  width: 100%;
}

.texim-ai .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
}

.texim-ai .text-wrapper-3 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 70px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin: auto;
}

.title:after {
  content: " ";
  display: block;
  border-bottom: 1px solid var(--brandpurple-6);
  border-top: 1px solid var(--brandpurple-6);
  height: 5px;
  width: 800px;
  background-color: #f8f8f8;
}

.texim-ai .p {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 691px;
}

.texim-ai .text-inputfiled {
  align-items: center;
  background-color: var(--brandpurple-5);
  border-radius: 99px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
}

.texim-ai .AI-icon {
  height: 35px;
  width: 35px;
  flex: 0 0 auto;
  position: relative;
  background-image: url(../../../static/img/AI-Icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.texim-ai .text-wrapper-4 {
  color: var(--brandpink-1);
  font-family: "Akaya Kanadaka", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.texim-ai .card-animation {
  align-self: stretch;
  background-image: url(https://c.animaapp.com/DRqVhl38/img/hero-animation.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
}

.texim-ai .bg {
  /* background-image: url(https://c.animaapp.com/DRqVhl38/img/tarot-screen.png); */
  background-size: 100% 100%;
  height: 1024px;
  margin-left: -120px;
  margin-right: -120px;
  position: relative;
  width: 1440px;
}

.texim-ai .frame {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10.25px;
  left: 792px;
  position: relative;
  top: 829px;
}

.texim-ai .group {
  height: 41px;
  margin-right: -2px;
  position: relative;
  width: 105.71px;
}

.texim-ai .overlap-group {
  background: linear-gradient(
    180deg,
    rgba(173.19, 10.82, 10.82, 0.75) 0%,
    rgba(252.88, 190.04, 28.45, 0.75) 100%
  );
  border: 0.8px solid;
  border-color: #fcbe1c;
  border-radius: 12.06px;
  height: 41px;
  position: relative;
  width: 104px;
}

.texim-ai .text-wrapper-5 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 22.4px;
  font-weight: 400;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
}

.texim-ai .text-wrapper-6 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 16.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.texim-ai .overlap {
  border: 0.8px solid;
  border-color: #fcbe1c;
  border-radius: 12.06px;
  height: 41px;
  position: relative;
  width: 104px;
}

.texim-ai .text-wrapper-7 {
  color: #fcbe1c;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 22.4px;
  font-weight: 400;
  left: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
}

.texim-ai .CTA-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 56px;
  padding: 64px 120px 72px;
  position: relative;
  width: 100%;
}

.texim-ai .CTA-container {
  align-items: center;
  background: linear-gradient(180deg, rgb(43, 36, 60) 0%, rgb(11, 3, 30) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(238, 238, 240),
      rgba(238, 238, 240, 0)
    )
    1;
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 56px 32px;
  position: relative;
  width: 1200px;
}

.texim-ai .CTA-text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
}

.texim-ai .CTA-brand-logos {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  padding: 8px 6px;
  position: relative;
}

.texim-ai .text-wrapper-8 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.texim-ai .CTA-heading {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.texim-ai .CTA-button {
  align-items: center;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(180.57, 42.79, 186.48) 4.67deg,
    rgb(161.31, 44.1, 188.03) 23.65deg,
    rgb(139.79, 45.57, 189.77) 44.86deg,
    rgb(120.6, 46.88, 191.32) 72.46deg,
    rgb(107.99, 47.75, 192.34) 82.5deg,
    rgb(75, 50, 195) 127.99deg,
    rgb(87.68, 49.13, 193.98) 160.97deg,
    rgb(99.31, 48.34, 193.04) 178.46deg,
    rgb(115.81, 47.21, 191.7) 189.48deg,
    rgb(141.11, 45.48, 189.66) 202.95deg,
    rgb(166.38, 43.76, 187.62) 230.66deg,
    rgb(185.39, 42.46, 186.09) 251.35deg,
    rgb(208.43, 40.88, 184.23) 276.44deg,
    rgb(236, 39, 182) 306.45deg,
    rgb(199.41, 41.5, 184.95) 331.68deg
  );
  border-radius: 99px;
  box-shadow: 0px 0px 60px #ec27b699;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 22px 32px;
  position: relative;
  width: 240px;
}

.CTA-button:hover {
  cursor: pointer;
}

.texim-ai .text-wrapper-9 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.texim-ai .copyright {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: center;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.texim-ai .text {
  color: var(--brand-colourseasalt);
  font-family: var(--caption-14-reg-font-family);
  font-size: var(--caption-14-reg-font-size);
  font-style: var(--caption-14-reg-font-style);
  font-weight: var(--caption-14-reg-font-weight);
  letter-spacing: var(--caption-14-reg-letter-spacing);
  line-height: var(--caption-14-reg-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.company-logo {
  background-image: url(../../../static/img/caelusium_landing.png);
}
