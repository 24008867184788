.inputError {
  outline: 1.5px solid #d294ff;
  border: none;
}

.inputField {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  color: white;
}

.inputForm {
  width: 80vw;
}

.textFieldMargin {
  margin-bottom: 1.2rem !important;
}

.textFieldError {
  margin-bottom: 0 !important;
}

.error {
  font-size: 1.5vh;
}
