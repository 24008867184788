.aboutUsContainer {
  align-items: center;
  background-color: var(--brandpurple-6);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.navbar-logo-left {
  align-items: flex-start;
  align-self: stretch;
  background: linear-gradient(to bottom, rgb(25, 14, 70), transparent);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.homeButton{
  width: 100%;
  margin-left: 0;
}

.homeIconContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.buttonHomeContainer{
  margin-right:5vw !important;
}

@media (min-width: 768px) {
  .homeButton{
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-logo-left{
    display: none;
  }
}

.navbar-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 16px 8px;
  position: relative;
  width: 1200px;
}

.navbar-content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.brand-logos {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 6px;
  position: relative;
}

.text-wrapper {
  color: var(--brandoff-white);
  font-family: "Almendra", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.navbar-menu {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.navbar-link {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  padding: 24px 12px;
  position: relative;
}

.navbar-div {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.navbar-button {
  align-items: center;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(180.57, 42.79, 186.48) 4.67deg,
    rgb(161.31, 44.1, 188.03) 23.65deg,
    rgb(139.79, 45.57, 189.77) 44.86deg,
    rgb(120.6, 46.88, 191.32) 72.46deg,
    rgb(107.99, 47.75, 192.34) 82.5deg,
    rgb(75, 50, 195) 127.99deg,
    rgb(87.68, 49.13, 193.98) 160.97deg,
    rgb(99.31, 48.34, 193.04) 178.46deg,
    rgb(115.81, 47.21, 191.7) 189.48deg,
    rgb(141.11, 45.48, 189.66) 202.95deg,
    rgb(166.38, 43.76, 187.62) 230.66deg,
    rgb(185.39, 42.46, 186.09) 251.35deg,
    rgb(208.43, 40.88, 184.23) 276.44deg,
    rgb(236, 39, 182) 306.45deg,
    rgb(199.41, 41.5, 184.95) 331.68deg
  );
  border-radius: 99px;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 32px;
  position: relative;
}

.navbar-button:hover {
  box-shadow: 0px 0px 60px #ec27b699;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.text-wrapper-2 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.aboutUsTitle {
  color: white;
  font-size: 5vh;
  padding-bottom: 2vh
}

.aboutUsContent{
  /* margin: auto; */
  color:white;
  text-align: center;
  font-size: 1.7vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.logoContainer{
  display: flex;
  align-items: center;
  /* padding-top: 10vh; */
  width:400px;
  height:400px;
  margin-bottom:-100px
}

.logo{
  left: 0;
  /* position: absolute; */
  top: 0;
  background-image: url(../../../static/caelusiumLogo.png);
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: contain;
}