/* @font-face {
  font-family: montserratLight;
  src: url(../../../assets/font/Montserrat-Light.otf);
} */
.people-top-border {
  border-top-color: #ffb700;
}

.hair-top-border {
  border-top-color: #fc5901;
}

.beauty-top-border {
  border-top-color: #ff5977;
}

.fashion-top-border {
  border-top-color: #ba29ba;
}

.mind-top-border {
  border-top-color: #02a4d3;
}

.living-top-border {
  border-top-color: #9ec600;
}

@media screen and (max-width: 1024px) {
  .Article-bottom-padding {
    padding-bottom: calc(30vw - 20px);
  }
}
.Top-Spacing-Row {
  height: 10px;
}

.Article-Heading-Container {
  margin-left: auto;
}

.Article-Heading {
  display: flex;
  flex-wrap: nowrap;
  max-width: 500px;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .Article-Heading {
    max-width: none;
  }
  .Issue-Container {
    width: 16.268% !important;
  }
  .Article-Title-Container {
    width: 83.398% !important;
  }
  .Article-Title {
    font-size: 2.2rem;
    padding-top: 20px;
  }
  .Article-Category-Icon-Container {
    width: 218px !important;
    top: -10px;
  }
}
.Article-Heading > div {
  padding: 16px;
}

.Issue-Container {
  width: 30%;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  height: 60px;
  padding: 0px 10px 10px 10px !important;
}

.Issue-Container p {
  margin: 2px;
}

.Issue {
  position: relative;
}

.Issue-Number {
  font-size: 40px;
  margin: 0px;
}

.Article-Title-Container {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  justify-content: flex-end;
}

.Article-Title-Wrapper {
  padding: 14px 16px 16px 0px;
  position: relative;
}

.divider {
  border-top-style: solid;
  border-width: 1px !important;
  margin: 0;
}

.Article-Category-Icon-Container {
  width: 88px;
  height: 18px;
  position: relative;
}

.Article-Title {
  font-family: MStiffHeiMedium;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
  padding-top: 10px;
}

.Article-Content-Container-mobile {
  width: 100%;
  box-sizing: border-box;
  margin-top: 62px;
  padding: 0% 3% 3% 3%;
  position: relative;
  background-repeat: repeat;
  background-position: 0px -50px;
  background-size: cover;
}

.Category-Icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: start;
  width: auto !important;
  height: 100%;
}

.Article-Content {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  left: 0;
  margin: auto;
}

.Article-Content-Col {
  padding: 0% 12% 8% 12%;
  /* background-image: url(../../../assets/img/paper_bg.jpeg) !important; */
  background-repeat: repeat;
  position: relative;
  top: -60px;
}

.Image-Column-desktop {
  display: none;
}

.Image-Swiper {
  height: auto !important;
  margin: auto;
}

.Article-Image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 99%;
  display: block;
  margin: auto;
}

.Text-Column {
  height: 45vh;
  margin-top: 18px !important;
  padding-right: 6px;
  overflow-y: scroll;
}

.Text-Column-desktop {
  display: none;
}

.Article-Text-Content {
  margin-bottom: 10px;
  font-weight: 300 !important;
  font-size: 12px !important;
}

.Article-Text-Title {
  margin-bottom: 12px;
  font-size: 12pt;
  font-family: arial, helvetica, sans-serif;
}

.React-Markdown-HTML h2 {
  font-size: 12pt;
  font-weight: normal;
  font-family: arial, helvetica, sans-serif;
  color: #000000;
  line-height: 1.6;
}

.React-Markdown-HTML p {
  color: #000000;
  font-size: 12px;
  line-height: 2.2;
  font-weight: 300 !important;
  font-family: montserratLight, "MHei HK" !important;
}

@media screen and (max-width: 1024px) {
  .Article-Page-Wrapper {
    background-image: none !important;
  }
}
.Image-Column-desktop-wrapper {
  display: none;
}

@media screen and (min-width: 1025px) {
  .Article-bottom-padding {
    padding-bottom: calc(30vw - 20%);
  }
  .background-limit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Article-Page-Wrapper {
    background-repeat: repeat;
    background-size: 878px;
    margin-top: 300px;
    width: 1025px;
  }
  .Article-Page-Content {
    position: relative;
    top: -300px;
    display: flex;
    /* background-image: url(../../../assets/img/paper_bg.jpeg) !important; */
    background-repeat: repeat;
  }
  .Article-Heading {
    max-height: 1135px;
    width: 920px;
    margin-bottom: 60px;
  }
  .Article-Title-Container {
    justify-content: flex-start;
    padding-bottom: 78px !important;
  }
  .Issue-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 8% !important;
  }
  .Issue {
    text-align: right;
    font-size: 42px;
  }
  .Issue-Number {
    font-size: 94px;
  }
  .Category-Icon {
    width: auto;
  }
  .Article-Title {
    font-size: 38px;
    font-family: MStiffHeiMedium;
  }
  .Top-Spacing-Row {
    height: 60px;
  }
  .Image-Column-desktop-wrapper {
    height: 1135px;
    display: block;
    width: 34%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .Image-Column-desktop {
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    padding: 0px;
  }
  .Text-Column-desktop {
    box-sizing: border-box;
    display: block;
    overflow-y: scroll !important;
    padding: 0px 78px 0px 0px;
    -ms-overflow-style: scroll !important; /* Internet Explorer 10+ */
    scrollbar-width: 20px !important; /* Firefox */
  }
  .Text-Column-desktop::-webkit-scrollbar {
    display: none; /* Safari and Chrome  */
  }
  .Article-Text-Content {
    font-size: 16px !important;
  }
  .React-Markdown-HTML h2 {
    font-size: 24px;
    letter-spacing: normal;
    line-height: 1.6;
    font-weight: bold;
    color: #303030;
  }
  .React-Markdown-HTML p {
    font-size: 12pt;
    color: #303030;
    font-size: 12pt;
    line-height: 1.8;
  }
  .Article-Text-Title {
    font-size: 24pt !important;
  }
  .Article-Image-Container {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
    cursor: pointer;
  }
  .Article-Image {
    width: 100%;
    filter: brightness(100%);
    transition: filter 0.5s ease-in-out;
    will-change: filter;
  }
  .Article-Image-Container:hover .Article-Image {
    filter: brightness(50%);
  }
  .Article-Image-Desc {
    position: absolute;
    bottom: 10px;
    width: 100%;
    right: 0px;
    opacity: 0%;
    transition: opacity 0.5s ease-in-out;
  }
  .Article-Image:hover ~ .Article-Image-Desc,
  .Article-Image-Desc:hover {
    opacity: 100%;
  }
  .Article-Title-Container {
    width: 58% !important;
  }
  .Article-Content-Container-mobile {
    display: none;
  }
  .divider {
    width: 567px;
  }
}