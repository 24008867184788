.question-container {
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 80vh;
}

.question {
  font-size: 2.2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 20px;
  background-color: black;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.quiz-image {
  border-radius: 20px;
  width: 100%;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #696b78;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  margin-top: 3vh;
  width: 80%;
}

.buttonContainer-selected {
  transform: scale(1.05);
  background-color: #6b63b5;
  color: black;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  margin-top: 3vh;
  width: 80%;
}

.confirmButton {
  color: white;
  padding: 2vh;
}

.image-selected {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin: none !important;

  .quiz-image {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
}
