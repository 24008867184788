.overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-in {
  animation: fade-in 0.5s;
}

.answer-container {
  padding-top: 1vh;
  max-width: 80vw;
}

@media screen and (min-width: 768px) {
  .answer-container {
    max-width: 20vw;
  }

  .answer-title {
    font-size: 3.4vh;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.result-image {
  max-width: 100%;
  max-height: 100%;
}

.overlay-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.overlay-button:hover {
  background-color: #aaa;
}

.buttonAnswerContainer {
  transform: scale(1.05);
  background-color: #6b63b5;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  margin-top: 3vh;
  /* width: 80%; */
  color: white;
  padding: 1vh;
  flex: 8;
  height: 5vh
}

.buttonHomeContainer{
  transform: scale(1.05);
  background-color: #6b63b5;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  margin-top: 3vh;
  /* width: 80%; */
  color: white;
  padding: 1vh;
  flex: 2;
  height: 5vh

}

.answer-title {
  font-size: 3vh;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-bottom: 3vh;
}

.buttonGroup{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 3vw;
}

.homeIconContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.homeIcon {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-image: url('../../../static/icons8-home-69.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}