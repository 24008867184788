.tarot-screen {
  background-color: #0d0420;
  height: 1024px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.tarot-screen .overlap {
  height: 776px;
  left: 0;
  position: absolute;
  top: 176px;
  width: 681px;
}

.tarot-screen .overlap-group {
  height: 776px;
  left: 0;
  position: absolute;
  top: 0;
  width: 681px;
}

.tarot-screen .ellipse {
  height: 384px;
  left: 0;
  position: absolute;
  top: 0;
  width: 332px;
  background-image: url(../../../static/img/ellipse-2.svg);
}

.tarot-screen .div {
  background-color: #fcbe1ccc;
  border-radius: 132.44px/167.35px;
  filter: blur(266.49px);
  height: 335px;
  left: 416px;
  position: absolute;
  top: 21px;
  width: 265px;
}

.tarot-screen .star {
  height: 53px;
  left: 295px;
  position: absolute;
  top: 3px;
  width: 49px;
  background-image: url(../../../static/img/star-5.svg);
}

.tarot-screen .img {
  height: 10px;
  left: 640px;
  position: absolute;
  top: 261px;
  width: 8px;
  background-image: url(../../../static/img/star-12.svg);
}

.tarot-screen .frame {
  height: 665px;
  left: 48px;
  position: absolute;
  top: 111px;
  width: 604px;
}

.tarot-screen .frame-2 {
  height: 709px;
  position: relative;
  top: -44px;
  width: 605px;
}

.tarot-screen .group {
  background-image: url(../../../static/img/rectangle-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 709px;
  left: 0;
  position: absolute;
  top: 0;
  width: 184px;
}

.tarot-screen .overlap-group-wrapper {
  height: 709px;
  width: 186px;
}

.tarot-screen .overlap-group-2 {
  background-color: #ffffff3b;
  border-radius: 115.52px 115.52px 0px 0px;
  height: 709px;
  position: relative;
  width: 184px;
}

.tarot-screen .rectangle {
  background-color: #ffffff4a;
  border: 0.58px solid;
  border-color: #fcbe1c;
  border-radius: 8.66px;
  height: 30px;
  left: 26px;
  position: absolute;
  top: 652px;
  width: 132px;
}

.tarot-screen .text-wrapper {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 41px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 656px;
  white-space: nowrap;
  width: 105px;
}

.tarot-screen .overlap-2 {
  height: 709px;
  left: 211px;
  position: absolute;
  top: 0;
  width: 186px;
}

.tarot-screen .rectangle-2 {
  background-color: #6d0404;
  border-radius: 115.52px 115.52px 0px 0px;
  height: 709px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 183px;
}

.tarot-screen .line {
  height: 201px;
  left: 0;
  position: absolute;
  top: 239px;
  width: 184px;
  background-image: url(../../../static/img/line-25.svg);
  background-size: contain;
}

.tarot-screen .line-2 {
  height: 202px;
  left: 0;
  position: absolute;
  top: 359px;
  width: 184px;
  background-image: url(../../../static/img/line-26.svg);
  background-size: contain;
}

.tarot-screen .text-wrapper-2 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 43.3px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 356px;
  transform: rotate(-48deg);
  width: 175px;
}

.tarot-screen .rectangle-3 {
  background-color: #ffffff4a;
  border: 0.58px solid;
  border-color: #fcbe1c;
  border-radius: 8.66px;
  height: 30px;
  left: 26px;
  position: absolute;
  top: 651px;
  width: 132px;
}

.tarot-screen .text-wrapper-3 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 46px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 654px;
}

.tarot-screen .star-2 {
  height: 49px;
  left: 68px;
  position: absolute;
  top: 582px;
  width: 49px;
  background-image: url(../../../static/img/star-14.svg);
}

.tarot-screen .frame-3 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  left: 15px;
  position: absolute;
  top: 53px;
}

.tarot-screen .text-wrapper-4 {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 21.37px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 155.38px;
}

.tarot-screen .element-may {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 57.8px;
  font-weight: 700;
  height: 89.53px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 155.38px;
}

.tarot-screen .span {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 57.8px;
  font-weight: 700;
  letter-spacing: 0;
  position: absolute;
  left: 10px;
}

.tarot-screen .text-wrapper-5 {
  color: #fcbe1c;
  font-size: 31.8px;
  right: -60px;
  top: 25px;
}

.tarot-screen .text-wrapper-6 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 23.1px;
  font-weight: 400;
  height: 21.37px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 155.38px;
}

.tarot-screen .group-2 {
  background-color: #1b1b1b;
  border: 1.73px solid;
  border-color: #ad0a0a;
  height: 709px;
  left: 421px;
  position: absolute;
  top: 0;
  width: 184px;
}

.tarot-screen .vecteezy-tarot {
  height: 60px;
  left: 46px;
  position: absolute;
  top: 639px;
  width: 93px;
  background-image: url(../../../static/img/vecteezy-tarot-symbol-set-illustrated-in-golden-brown-5568261-2.png);
  background-size: contain;
}

.tarot-screen .group-3 {
  height: 100%;
  position: absolute;
  width: 100%;
}

.tarot-screen .overlap-group-3 {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.tarot-screen .text-wrapper-7 {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.tarot-screen .vecteezy-tarot-2 {
  height: 177px;
  left: 2px;
  position: absolute;
  top: 27px;
  width: 124px;
  background-image: url(../../../static/img/vecteezy-tarot-symbol-set-illustrated-in-golden-brown-5568261-1.png);
  background-size: contain;
}

.tarot-screen .frame-4 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 13px;
  left: 2px;
  position: absolute;
  top: 224px;
}

.tarot-screen .text-wrapper-8 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 21.37px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 155.38px;
}

.tarot-screen .a-common-aspect-to {
  color: #ffffff;
  font-family: "Trirong", Helvetica;
  font-size: 13.5px;
  font-weight: 500;
  height: 201.58px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 144.14px;
}

.tarot-screen .group-4 {
  height: 4px;
  left: 152px;
  position: absolute;
  top: 90px;
  transform: rotate(85deg);
  width: 10px;
}

.tarot-screen .line-3 {
  height: 7px;
  left: 0;
  position: absolute;
  top: -2px;
  transform: rotate(-85deg);
  width: 5px;
  background-image: url(../../../static/img/line-27-1.svg);
  background-size: contain;
}

.tarot-screen .line-4 {
  height: 6px;
  left: 5px;
  position: absolute;
  top: -1px;
  transform: rotate(-85deg);
  width: 4px;
  background-image: url(../../../static/img/line-28-1.svg);
  background-size: contain;
}

.tarot-screen .group-5 {
  height: 4px;
  left: -3px;
  position: absolute;
  top: 88px;
  transform: rotate(-95deg);
  width: 10px;
}

.tarot-screen .line-5 {
  height: 7px;
  left: 0;
  position: absolute;
  top: -2px;
  transform: rotate(95deg);
  width: 5px;
  background-image: url(../../../static/img/line-27.svg);
  background-size: contain;
}

.tarot-screen .line-6 {
  height: 6px;
  left: 5px;
  position: absolute;
  top: -1px;
  transform: rotate(95deg);
  width: 4px;
  background-image: url(../../../static/img/line-28.svg);
  background-size: contain;
}

.tarot-screen .star-3 {
  height: 19px;
  left: 26px;
  position: absolute;
  top: 595px;
  width: 20px;
  background-image: url(../../../static/img/star-3.svg);
  background-size: contain;
}

.tarot-screen .rectangle-4 {
  height: 893px;
  left: -7442px;
  object-fit: cover;
  position: absolute;
  top: 4148px;
  width: 771px;
  background-image: url(../../../static/img/rectangle-15.png);
  background-size: contain;
}

.tarot-screen .line-7 {
  height: 2px;
  left: 163px;
  position: absolute;
  top: 82px;
  width: 504px;
  background-image: url(../../../static/img/line-13.svg);
  background-size: contain;
}

.tarot-screen .line-8 {
  height: 2px;
  left: 381px;
  position: absolute;
  top: 165px;
  width: 288px;
  background-image: url(../../../static/img/line-15.svg);
  background-size: contain;
}

.tarot-screen .text-wrapper-9 {
  color: #fcbe1cbf;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 48px;
  font-weight: 400;
  left: 166px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 105px;
  white-space: nowrap;
}

.tarot-screen .overlap-3 {
  height: 877px;
  left: 683px;
  position: absolute;
  top: 75px;
  width: 284px;
}

.tarot-screen .star-4 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 35px;
  background-image: url(../../../static/img/star-3.svg);
  background-size: contain;
}

.tarot-screen .overlap-4 {
  height: 877px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 258px;
}

.tarot-screen .line-9 {
  height: 820px;
  left: 2px;
  position: absolute;
  top: 53px;
  width: 1px;
  background-image: url(../../../static/img/line-14.svg);
  background-size: contain;
}

.tarot-screen .line-10 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 666px;
  width: 255px;
  background-image: url(../../../static/img/line-17.svg);
  background-size: contain;
}

.tarot-screen .line-11 {
  height: 2px;
  left: 3px;
  position: absolute;
  top: 136px;
  width: 255px;
  background-image: url(../../../static/img/line-18.svg);
  background-size: contain;
}

.tarot-screen .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  height: 322px;
  left: 40px;
  position: absolute;
  top: 0;
}

.tarot-screen .p {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 53.3px;
  font-weight: 400;
  height: 80px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 73.02px;
}

.tarot-screen .text-wrapper-10 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 53.3px;
  font-weight: 400;
  letter-spacing: 0;
}

.tarot-screen .text-wrapper-11 {
  position: absolute;
  top: 60px;
  right: -10px;
  font-size: 26.6px;
  width: 5vw;
}

.tarot-screen .text-wrapper-12 {
  color: #ffffff;
  font-family: "Trirong", Helvetica;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.tarot-screen .text-wrapper-13 {
  color: #ffffff;
  font-family: "Trirong", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 162.56px;
  letter-spacing: 0;
  position: relative;
  width: 211.59px;
}

.tarot-screen .ellipse-2 {
  background-color: #fcbe1c;
  border-radius: 2.66px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 872px;
  width: 5px;
}

.tarot-screen .membership-comes {
  color: #c4951c;
  font-family: "Trirong", Helvetica;
  font-size: 64px;
  font-weight: 600;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 681px;
}

.tarot-screen .star-5 {
  height: 32px;
  left: 119px;
  position: absolute;
  top: 19px;
  width: 35px;
  background-image: url(../../../static/img/star-16.svg);
  background-size: contain;
}

.tarot-screen .group-6 {
  height: 883px;
  left: 979px;
  position: absolute;
  top: 70px;
  width: 423px;
  background-size: contain;
}

.tarot-screen .subtract {
  height: 1px;
  left: -6395px;
  object-fit: cover;
  position: absolute;
  top: 3701px;
  width: 1px;
  background-image: url(../../../static/img/subtract.png);
  background-size: contain;
}
/* 
.card-animation {
  align-self: stretch;
  background-image: url(https://c.animaapp.com/DRqVhl38/img/card-animation.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 648px;
  position: relative;
  width: 100%;
} */
