.quiz-container {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 80vh;
}

#submit {
  text-align: center;
  margin: 20px 0;
}

.result-image {
  width: 80vw;
}

#answers {
  max-width: 30vw;
  min-width: 480px;
  height: fit-content;
}
