.formContainerResult {
  margin: auto;
  padding-top: 30vh;
}

@media (max-width: 768px) {
  .formContainerResult {
    max-width: 70vw;
  }
}

@media (min-width: 769px) {
  .formContainerResult {
    max-width: 65vh;
  }
}

.inputLabelResult {
  color: white;
  font-size: 2vh;
  text-align: center;
}

.formTitleResult {
  color: white;
  font-size: 3.5vh;
  text-align: center;
  padding-bottom: 7vh;
}
