.formContainer {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 80vh;
}

.signUpTitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: white;
    text-align: center;
    padding: 1rem;
}

.error {
    color: #d294ff;
    font-size: 0.8rem;
    /* margin-bottom: 0.5rem; */
    font-weight: 800;
}

.inputLabel {
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 0;
    color: white;
}

.nameInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    /* margin-top: 1rem; */
}

.doubleLine {
    width: 20vw;
    line-height: 1;
}

.doubleLineName {
    width: 10vw;
    line-height: 1;
}

.signUpButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.5rem;
    background-color: #6b63b5;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
}

.inputError {
    outline: 1.5px solid #d294ff;
    border: none;
}

.inputField {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    color: white;
}

.inputForm {
    width: 80vw;
}

.textFieldMargin {
    margin-bottom: 1.2rem !important;
}

.textFieldError {
    margin-bottom: 0 !important;
}