.m-frame {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border: 1px solid;
  border-color: #e4e4e4;
  border-radius: 25px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.m-frame .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 5px 70px;
  position: relative;
  width: 100%;
}

.m-frame .element-may {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 53.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.m-frame .text-wrapper {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 53.3px;
  font-weight: 400;
  letter-spacing: 0;
}

.m-frame .span {
  font-size: 26.6px;
}

.m-frame .text-wrapper-2 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.m-frame .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 715.76px;
  justify-content: flex-end;
  margin-top: -100px;
  padding: 0px 40px;
  position: relative;
  width: 100%;
}

.m-frame .group {
  flex: 1;
  flex-grow: 1;
  height: 515.76px;
  position: relative;
}

.m-frame .rectangle {
  height: 537px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 308px;
  /* background-image: url("../../../static/img/rectangle-6.png"); */
  background-size: contain;
}

.m-frame .rectangle-2 {
  align-self: stretch;
  background-color: #6d0404;
  border: 0.53px solid;
  border-color: #ffffff;
  border-radius: 159.89px;
  box-shadow: 13.32px 10.66px 10.66px #00000040;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.m-frame .div-3 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 11px;
  justify-content: center;
  left: 88px;
  padding: 40px 0px;
  position: absolute;
  top: 0;
}

.m-frame .text-wrapper-3 {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 21.37px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 155.38px;
}

.m-frame .p {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 57.8px;
  font-weight: 700;
  height: 89.53px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 155.38px;
}

.m-frame .text-wrapper-4 {
  color: #fcbe1c;
  font-family: "Trirong", Helvetica;
  font-size: 57.8px;
  font-weight: 700;
  letter-spacing: 0;
}

.m-frame .text-wrapper-5 {
  color: white;
  font-size: 31.8px;
}

.m-frame .text-wrapper-6 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 23.1px;
  font-weight: 400;
  height: 21.37px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 155.38px;
}

.m-frame .a-common-aspect-to {
  color: #ffffff;
  font-family: "Trirong", Helvetica;
  font-size: 13.5px;
  font-weight: 500;
  height: 202px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 188px;
}

.m-frame .div-4 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  margin-top: 65px;
  position: relative;
  width: 364.5px;
}

.m-frame .overlap-group-wrapper {
  flex: 1;
  flex-grow: 1;
  height: 49px;
  position: relative;
  transform: rotate(180deg);
}

.m-frame .overlap-group {
  border-radius: 29.31px 0px 0px 0px;
  height: 49px;
  position: relative;
  width: 52px;
}

.m-frame .rectangle-3 {
  background-color: #000000;
  border: 0.53px solid;
  border-color: #ffffff80;
  border-radius: 29.31px 0px 0px 0px;
  height: 49px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-360deg);
  width: 52px;
}

.m-frame .group-2 {
  height: 39px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 37px;
}

.m-frame .star {
  height: 19px;
  left: 2px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 24px;
  background-image: url("../../../static/img/star-15-3.svg");
  background-size: contain;
}

.m-frame .img {
  height: 15px;
  left: 1px;
  position: absolute;
  top: 24px;
  transform: rotate(-180deg);
  width: 15px;
  background-image: url("../../../static/img/star-16-3.svg");
  background-size: contain;
}

.m-frame .ellipse {
  background-color: #fcbe1c;
  border-radius: 3.69px/3.02px;
  height: 6px;
  left: 29px;
  position: absolute;
  top: 17px;
  width: 7px;
}

.m-frame .div-wrapper {
  align-items: flex-end;
  background-color: #000000;
  border: 0.53px solid;
  border-color: #c9c8cb;
  border-radius: 90px 90px 0px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 50px;
  position: relative;
}

.m-frame .text-wrapper-7 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.53px;
  position: relative;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
  white-space: nowrap;
  width: fit-content;
}

.m-frame .overlap-wrapper {
  flex: 1;
  flex-grow: 1;
  height: 49px;
  position: relative;
}

.m-frame .rectangle-4 {
  background-color: #000000;
  border: 0.53px solid;
  border-color: #ffffff80;
  border-radius: 29.31px 0px 0px 0px;
  height: 49px;
  left: 0;
  position: absolute;
  top: 0;
  width: 52px;
}

.m-frame .group-3 {
  height: 39px;
  left: 8px;
  position: absolute;
  top: 10px;
  width: 37px;
}

.m-frame .star-2 {
  height: 19px;
  left: 2px;
  position: absolute;
  top: 19px;
  width: 24px;
  background-image: url("../../../static/img/star-15-2.svg");
  background-size: contain;
}

.m-frame .star-3 {
  height: 15px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 15px;
  background-image: url("../../../static/img/star-16-2.svg");
  background-size: contain;
}

.m-frame .ellipse-2 {
  background-color: #fcbe1c;
  border-radius: 3.69px/3.02px;
  height: 6px;
  left: 29px;
  position: absolute;
  top: 16px;
  width: 7px;
}
