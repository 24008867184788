.m-iphone-pro {
  background-color: #0b031e;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.m-iphone-pro .m-div-5 {
  background-color: var(--bg);
  height: 1800px;
  overflow: hidden;
  position: relative;
  width: 393px;
}

.m-iphone-pro .m-overlap {
  height: 600px;
  left: 26px;
  position: relative;
  top: 1400px;
  width: 340px;
}

.m-iphone-pro .m-frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 0px;
  top: 215px;
  margin: 20px;
}

.m-iphone-pro .m-CTA-container {
  align-items: center;
  background: linear-gradient(180deg, rgb(43, 36, 60) 0%, rgb(11, 3, 30) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(238, 238, 240),
      rgba(238, 238, 240, 0)
    )
    1;
  border-radius: 32px;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  /* height: 302px; */
  justify-content: center;
  padding: 56px 32px;
  position: relative;
}

.m-iphone-pro .m-CTA-text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  margin-top: -49.5px;
  padding: 24px 10px;
  position: relative;
  width: 100%;
}

.m-iphone-pro .m-CTA-heading {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.m-iphone-pro .m-join-the-community {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.m-iphone-pro .m-start-transforming {
  align-self: stretch;
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.m-iphone-pro .m-CTA-button {
  align-items: center;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(180.57, 42.79, 186.48) 4.67deg,
    rgb(161.31, 44.1, 188.03) 23.65deg,
    rgb(139.79, 45.57, 189.77) 44.86deg,
    rgb(120.6, 46.88, 191.32) 72.46deg,
    rgb(107.99, 47.75, 192.34) 82.5deg,
    rgb(75, 50, 195) 127.99deg,
    rgb(87.68, 49.13, 193.98) 160.97deg,
    rgb(99.31, 48.34, 193.04) 178.46deg,
    rgb(115.81, 47.21, 191.7) 189.48deg,
    rgb(141.11, 45.48, 189.66) 202.95deg,
    rgb(166.38, 43.76, 187.62) 230.66deg,
    rgb(185.39, 42.46, 186.09) 251.35deg,
    rgb(208.43, 40.88, 184.23) 276.44deg,
    rgb(236, 39, 182) 306.45deg,
    rgb(199.41, 41.5, 184.95) 331.68deg
  );
  border-radius: 99px;
  box-shadow: 0px 0px 60px #ec27b699;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -49.5px;
  padding: 22px 32px;
  position: relative;
  width: 240px;
}

.m-iphone-pro .m-text-wrapper-8 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.m-iphone-pro .m-copyright {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: center;
  padding: 16px 0px;
  position: relative;
  width: 100%;
}

.m-iphone-pro .m-text {
  color: #fafaf9;
  font-family: var(--caption-14-reg-font-family);
  font-size: var(--caption-14-reg-font-size);
  font-style: var(--caption-14-reg-font-style);
  font-weight: var(--caption-14-reg-font-weight);
  letter-spacing: var(--caption-14-reg-letter-spacing);
  line-height: var(--caption-14-reg-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.m-iphone-pro .m-frame-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
}

.m-iphone-pro .m-frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.m-iphone-pro .m-text-wrapper-9 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.12px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.m-iphone-pro .m-frame-4 {
  -webkit-backdrop-filter: blur(20px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(20px) brightness(100%);
  background-color: #00000066;
  border: 1px solid;
  border-color: var(--gray);
  border-radius: 24px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 10px 30px;
  position: relative;
  width: 339px;
}

.m-iphone-pro .m-group-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  position: relative;
}

.m-iphone-pro .m-group-4m {
  height: 148px;
  position: relative;
  width: 96px;
  background-image: url(../../../static/img/group-7199-2.png);
  background-size: contain;
}

.m-iphone-pro .m-frame-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.m-iphone-pro .m-the-root-of-all-kind {
  color: var(--highlight);
  font-family: "Manrope", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.m-iphone-pro .m-text-wrapper-10 {
  color: var(--highlight);
  font-family: "Manrope", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.m-iphone-pro .m-text-wrapper-11 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  align-self: stretch;
  color: #b5b5b7;
  display: -webkit-box;
  flex: 1;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.06px;
  line-height: normal;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.m-iphone-pro .m-frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  left: 26px;
  position: absolute;
  top: 1883px;
}

.m-iphone-pro .m-frame-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: -1px;
  padding: 30px 20px;
  position: absolute;
  top: 1376px;
  width: 394px;
}

.m-iphone-pro .m-group-5 {
  height: 426.86px;
  margin-right: -21px;
  position: relative;
  width: 375px;
}

.m-iphone-pro .m-frame-8 {
  align-items: flex-start;
  display: flex;
  gap: 22px;
  left: 0;
  overflow-x: scroll;
  position: absolute;
  top: 137px;
  width: 373px;
}

.m-iphone-pro .m-group-6 {
  height: 289.86px;
  position: relative;
  width: 185.81px;
}

.m-iphone-pro .m-overlap-group-2 {
  height: 290px;
  position: relative;
  width: 182px;
}

.m-iphone-pro .m-visual-direction {
  color: #f5f5f580;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 243px;
}

.m-iphone-pro .m-visual-direction-2 {
  color: #f5f5f5;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 215px;
}

.m-iphone-pro .m-rectangle-5m {
  height: 181px;
  left: 12px;
  position: absolute;
  top: 14px;
  width: 157px;
  background-image: url(../../../static/img/rectangle-4.png);
  background-size: contain;
}

.m-iphone-pro .m-rectangle-6m {
  height: 290px;
  left: 0;
  position: absolute;
  top: 0;
  width: 182px;
  background-image: url(../../../static/img/rectangle-8.svg);
  background-size: contain;
}

.m-iphone-pro .m-rectangle-51m {
  height: 181px;
  left: 12px;
  position: absolute;
  top: 14px;
  width: 157px;
  background-image: url(../../../static/img/rectangle-9.png);
  background-size: contain;
}

.m-iphone-pro .m-rectangle-61m {
  height: 290px;
  left: 0;
  position: absolute;
  top: 0;
  width: 182px;
  background-image: url(../../../static/img/rectangle-10.svg);
  background-size: contain;
}
.m-iphone-pro .m-rectangle-52m {
  height: 181px;
  left: 12px;
  position: absolute;
  top: 14px;
  width: 157px;
  background-image: url(../../../static/img/rectangle-11.png);
  background-size: contain;
}

.m-iphone-pro .m-rectangle-62m {
  height: 290px;
  left: 0;
  position: absolute;
  top: 0;
  width: 182px;
  background-image: url(../../../static/img/rectangle-12.png);
  background-size: contain;
}

.m-iphone-pro .m-visual-direction-3 {
  color: #f5f5f5;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 34px;
  font-weight: 400;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.m-iphone-pro .m-overlap-2 {
  height: 1376px;
  left: 0;
  position: absolute;
  top: 0;
  width: 394px;
}

.m-iphone-pro .m-frame-7154 {
  height: 780px;
  left: 15px !important;
  position: absolute !important;
  top: 600px !important;
}

.m-iphone-pro .m-frame-9 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
  left: 0;
  padding: 30px 0px;
  /* position: absolute; */
  /* top: 49px; */
  margin-top: 6vh;
}

.m-iphone-pro .m-card-text {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
}

.m-iphone-pro .m-img-2m {
  flex: 0 0 auto;
  position: relative;
  background-image: url(../../../static/img/the-next-generation-of-fortune-telling.png);
  background-size: contain;
}

.m-iphone-pro .m-img-21m {
  flex: 0 0 auto;
  position: relative;
  background-image: url(../../../static/img/frame-7151.svg);
  background-size: contain;
}

.m-iphone-pro .m-text-wrapper-12 {
  align-self: stretch;
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.m-iphone-pro .text-wrapper-title {
  font-size: 40px;
  font-weight: 800;
  color: white;
}

.text-wrapper-title:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 10px;
}

.m-iphone-pro .m-IMAGE {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 119px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.m-iphone-pro .m-rectangle-7m {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
  background-image: url(../../../static/img/rectangle-16.png);
  background-size: contain;
}

.m-iphone-pro .m-card-AI-bar {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px 20px;
  position: relative;
}

.m-iphone-pro .m-text-inputfiled {
  align-items: center;
  background-color: var(--brandpurple-5);
  border-radius: 99px;
  display: flex;
  gap: 16px;
  height: 51px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  width: 353px;
}

.m-iphone-pro .m-AI-iconm {
  height: 27px;
  margin-bottom: -4px;
  margin-top: -4px;
  position: relative;
  width: 27px;
  background-image: url(../../../static/img/ai-icon2.svg);
  background-size: contain;
}

.m-iphone-pro .m-text-wrapper-13 {
  color: var(--brandpink-1);
  font-family: "Akaya Kanadaka", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.5px;
  margin-top: -2.5px;
  position: relative;
  width: fit-content;
}

.m-iphone-pro .m-frame-10 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  overflow-x: scroll;
  padding: 0px 20px;
  position: relative;
  width: 394px;
}

.m-iphone-pro .m-group-7 {
  height: 196.21px;
  position: relative;
  width: 198.62px;
}

.m-iphone-pro .m-tarot-reading-wrapperm {
  background-image: url(../../../static/img/frame-7137-3.svg);
  background-size: 100% 100%;
  height: 196px;
  position: relative;
  width: 197px;
}

.m-iphone-pro .m-tarot-reading {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 37.3px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 84px;
  width: 124px;
}

.m-iphone-pro .m-overlap-3m {
  background-image: url(../../../static/img/frame-7137-2.svg);
  background-size: 100% 100%;
  height: 196px;
  position: relative;
  width: 197px;
}

.m-iphone-pro .m-overlap-4m {
  background-image: url(../../../static/img/frame-7137-1.png);
  background-size: 100% 100%;
  height: 196px;
  position: relative;
  width: 197px;
}

.m-iphone-pro .m-overlap-5m {
  background-image: url(../../../static/img/frame-7137.png);
  background-size: 100% 100%;
  height: 196px;
  position: relative;
  width: 197px;
}

.m-iphone-pro .m-frame-11 {
  align-items: center;
  display: inline-flex;
  gap: 169px;
  justify-content: center;
  left: 1px;
  padding: 4px 20px;
  position: absolute;
  top: 0;
}

.m-iphone-pro .m-frame-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.m-iphone-pro .m-text-wrapper-14 {
  color: #ffffff;
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 318px;
}

.m-iphone-pro .m-navbar-button {
  align-items: center;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(180.57, 42.79, 186.48) 4.67deg,
    rgb(161.31, 44.1, 188.03) 23.65deg,
    rgb(139.79, 45.57, 189.77) 44.86deg,
    rgb(120.6, 46.88, 191.32) 72.46deg,
    rgb(107.99, 47.75, 192.34) 82.5deg,
    rgb(75, 50, 195) 127.99deg,
    rgb(87.68, 49.13, 193.98) 160.97deg,
    rgb(99.31, 48.34, 193.04) 178.46deg,
    rgb(115.81, 47.21, 191.7) 189.48deg,
    rgb(141.11, 45.48, 189.66) 202.95deg,
    rgb(166.38, 43.76, 187.62) 230.66deg,
    rgb(185.39, 42.46, 186.09) 251.35deg,
    rgb(208.43, 40.88, 184.23) 276.44deg,
    rgb(236, 39, 182) 306.45deg,
    rgb(199.41, 41.5, 184.95) 331.68deg
  );
  border-radius: 99px;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 32px;
  position: relative;
  box-shadow: 0px 0px 60px #ec27b699;
}

.m-iphone-pro .m-text-wrapper-2 {
  color: var(--brandoff-white);
  font-family: "Akaya Telivigala", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.company-logo {
  background-image: url(../../../static/img/caelusium_landing.png);
}
